import axios from 'axios';
import {Setup} from './Setup';

const API =  axios.create({
    baseURL: Setup.endpoint+"/starium/api/"+Setup.version
});

const API_PAYMENT =  axios.create({
    baseURL: Setup.payment_endpoint+"/api/"+Setup.version
});


export {API,API_PAYMENT};

