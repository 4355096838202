import {API} from "./Api"
import uniqid from 'uniqid';
import moment from "moment-jalaali";

async function login_api(mobile){
    return API.post(`/login`,{mobile})
}

async function login_username_api(obj){
    return API.post(`/login-username`,obj)
}

async function verify_api(verifyObj){
    return API.post(`/verify`,verifyObj)
}

async function setOrganName_api(organObj){
    const  token = localStorage.getItem("token")
    return API.put(`/organs/${organObj.organId}`,{name:organObj.name},{
        headers:{
            'x-access-token':token
        }
    })
}



async function getKpiPackage_api(group){
    const  token = localStorage.getItem("token")
    return API.post(`/kpi-positive-package-list`,{group},{
        headers:{
            'x-access-token':token
        }
    })
}


async function getGroups_api(page=0){
    const  token = localStorage.getItem("token")
    return API.get(`/groups`,{
        params:{
            page:page
        },
        headers:{
            'x-access-token':token
        }
    })
}






async function getOperationsUserReport_api(page=0,userId){
    const  token = localStorage.getItem("token")
    return API.post(`/user/operations-user-panel`,{userTarget:userId},{
        params:{
            page:page
        },
        headers:{
            'x-access-token':token
        }
    })
}


export async function getEmotionsUserReport_api(page=0,userId){
    const  token = localStorage.getItem("token")
    return API.post(`/user/statistic-emotions-user-panel`,{userTarget:userId},{
        params:{
            page:page
        },
        headers:{
            'x-access-token':token
        }
    })
}



export async function getEmotionsReport_api(page=0,groupId=null,start=null,end=null){
    const  token = localStorage.getItem("token")
    return API.post(`/user/statistic-emotions-panel`,{
        groupId:groupId,
        start:start,
        end:end
    },{
        params:{
            page:page,
        },
        headers:{
            'x-access-token':token
        }
    })
}

export async function getEmotionsReportSum_api(page=0,groupId=null,start=null,end=null){
    const  token = localStorage.getItem("token")
    return API.post(`/user/statistic-emotions-sum-panel`,{
        groupId:groupId,
        start:start,
        end:end
    },{
        params:{
            page:page,
        },
        headers:{
            'x-access-token':token
        }
    })
}



async function getOperationsReport_api(page=0,groupId=null,start=null,end=null){
    const  token = localStorage.getItem("token")
    return API.post(`/user/operations-panel`,{
        groupId:groupId,
        start:start,
        end:end
    },{
        params:{
            page:page
        },
        headers:{
            'x-access-token':token
        }
    })
}


export async function getKpiReport_api(page=0,groupId=null,start=null,end=null){
    const  token = localStorage.getItem("token")
    return API.post(`/kpi/kpi-main-report-panel`,{
        groupId:groupId,
        start:start,
        end:end
    },{
        params:{
            page:page
        },
        headers:{
            'x-access-token':token
        }
    })
}


export async function getKpiDetailReport_api(page=0,groupId=null,start=null,end=null,kpiId=null){
    const  token = localStorage.getItem("token")
    return API.post(`/kpi/kpi-main-report-detail-panel`,{
        groupId:groupId,
        start:start,
        end:end,
        kpiId:kpiId
    },{
        params:{
            page:page
        },
        headers:{
            'x-access-token':token
        }
    })
}



async function getOperationsSumReport_api(page=0,groupId=null,start=null,end=null){
    const  token = localStorage.getItem("token")
    return API.post(`/user/operations-sum-panel`,{
        groupId:groupId,
        start:start,
        end:end
    },{
        params:{
            page:page
        },
        headers:{
            'x-access-token':token
        }
    })
}




async function getMedias_api(page=0,filter=null){
    const  token = localStorage.getItem("token")
    return API.get(`/media`,{
        params:{
            page:page,
            type:filter
        },
        headers:{
            'x-access-token':token
        }
    })
}

async function getUserModes_api(page=0){
    const  token = localStorage.getItem("token")
    return API.get(`/modes`,{
        params:{
            page:page
        },
        headers:{
            'x-access-token':token
        }
    })
}



async function createGroup_api(groupObj){
    const  token = localStorage.getItem("token")
    return API.post(`/groups`,groupObj,{
        headers:{
            'x-access-token':token
        }
    })
}

async function updateGroup_api(id,groupObj){
    const  token = localStorage.getItem("token")
    return API.put(`/groups/${id}`,groupObj,{
        headers:{
            'x-access-token':token
        }
    })
}


async function deleteGroups_api(id){
    const  token = localStorage.getItem("token")
    return API.delete(`/groups/${id}`,{
        headers:{
            'x-access-token':token
        }
    })
}

async function deleteMedia_api(id){
    const  token = localStorage.getItem("token")
    return API.delete(`/media/${id}`,{
        headers:{
            'x-access-token':token
        }
    })
}

//packages




async function getAllUsers_api(page =0){
    const  token = localStorage.getItem("token")
    return API.get(`/users-all`,{
        headers:{
            'x-access-token':token
        }
    })

}


//admin
async function getChallengeAdmin_api(page =0){
    const  token = localStorage.getItem("token")
    return API.get(`/admin/challenges`,{
        params:{
            page:page
        },
        headers:{
            'x-access-token':token
        }
    })
}

async function getPackages_api(page =0){
    const  token = localStorage.getItem("token")
    return API.get(`/packages`,{
        params:{
            page:page
        },
        headers:{
            'x-access-token':token
        }
    })
}

export async function getKpi_api(page =0){
    const  token = localStorage.getItem("token")
    return API.get(`/kpi`,{
        params:{
            page:page
        },
        headers:{
            'x-access-token':token
        }
    })
}


async function getOrganAdmin_api(page =0){
    const  token = localStorage.getItem("token")
    return API.get(`/admin/organs`,{
        params:{
            page:page
        },
        headers:{
            'x-access-token':token
        }
    })
}

async function getBuys_api(page =0){
    const  token = localStorage.getItem("token")
    return API.get(`/buy`,{
        params:{
            page:page
        },
        headers:{
            'x-access-token':token
        }
    })
}

async function changeBuyStatus_api(id){
    const  token = localStorage.getItem("token")
    return API.put(`/buy/change-status/${id}`,{},{
        headers:{
            'x-access-token':token
        }
    })
}



async function createPackage_api(obj){
    const  token = localStorage.getItem("token")
    return API.post(`/packages`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}

async function createChallengeAdmin_api(obj){
    const  token = localStorage.getItem("token")
    return API.post(`/admin/challenges`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}

export async function createKpi_api(obj){
    const  token = localStorage.getItem("token")
    return API.post(`/kpi`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}

async function createOrganAdmin_api(obj){
    const  token = localStorage.getItem("token")
    return API.post(`/admin/organs`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}


async function updateChallengeAdmin_api(id,obj){
    const  token = localStorage.getItem("token")
    return API.put(`/admin/challenges/${id}`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}



async function updateRegistersAdmin_api(id,obj){
    const  token = localStorage.getItem("token")
    return API.put(`/admin/registers/${id}`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}


async function updatePackage_api(id,obj){
    const  token = localStorage.getItem("token")
    return API.put(`/packages/${id}`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}


export async function updateKpi_api(id,obj){
    const  token = localStorage.getItem("token")
    return API.put(`/kpi/${id}`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}


async function updateOrganAdmin_api(id,obj){
    const  token = localStorage.getItem("token")
    return API.put(`/admin/organs/${id}`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}



async function deleteTicket_api(id){
    const  token = localStorage.getItem("token")
    return API.delete(`/tickets/${id}`,{
        headers:{
            'x-access-token':token
        }
    })
}


async function deleteRegistersAdmin_api(id){
    const  token = localStorage.getItem("token")
    return API.delete(`/admin/registers/${id}`,{
        headers:{
            'x-access-token':token
        }
    })
}


async function deleteTicketAdmin_api(id){
    const  token = localStorage.getItem("token")
    return API.delete(`/admin/tickets/${id}`,{
        headers:{
            'x-access-token':token
        }
    })
}


export async function deleteKpi_api(pack){
    const  token = localStorage.getItem("token")
    return API.delete(`/kpi/${pack._id}`,{
        headers:{
            'x-access-token':token
        }
    })
}


async function deletePackage_api(pack){
    const  token = localStorage.getItem("token")
    return API.post(`/packages-delete`,{pack},{
        headers:{
            'x-access-token':token
        }
    })
}


async function deleteChallengeAdmin_api(pack){
    const  token = localStorage.getItem("token")
    return API.post(`/admin/challenge-delete`,{pack},{
        headers:{
            'x-access-token':token
        }
    })
}



//user

async function getUsers_api(page=0){
    const  token = localStorage.getItem("token")
    return API.get(`/users`,{
        params:{
            page:page
        },
        headers:{
            'x-access-token':token
        }
    })
}

async function createUser_api(obj){
    const  token = localStorage.getItem("token")
    return API.post(`/users`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}

export const getKpiUserDetail_api = (groupId=null,start=null,end=null,kpiId=null,targetId=null)=>{
    const token = localStorage.getItem("token")
    return API.post(`/kpi-user-report-detail-client-panel`,{
        groupId:groupId,
        start:start,
        end:end,
        kpiId:kpiId,
        targetId:targetId
    },{
        headers:{
            "x-access-token":token
        }
    })
}

export const getKpiUserApi = (groupId=null,start=null,end=null,targetId=null)=>{
    const token = localStorage.getItem("token")
    return API.post(`/kpi-user-report-client-panel`,{
        groupId:groupId,
        start:start,
        end:end,
        targetId:targetId
    },{
        headers:{
            "x-access-token":token
        }
    })
}

async function updateUser_api(id,obj){
    const  token = localStorage.getItem("token")
    return API.put(`/users/${id}`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}



async function deleteUser_api(user){
    const  token = localStorage.getItem("token")
    return API.post(`/user-delete`,{user},{
        headers:{
            'x-access-token':token
        }
    })
}

//organ
async function getOrgan_api(){
    const  token = localStorage.getItem("token")
    return API.get(`/organ`,{
        headers:{
            'x-access-token':token
        }
    })
}

async function getPlan_api(type){
    const  token = localStorage.getItem("token")
    return API.get(`/plan/${type}`,{
        headers:{
            'x-access-token':token
        }
    })
}


async function updateMobile_api(obj){
    const  token = localStorage.getItem("token")
    return API.post(`/update-mobile`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}

async function verifyMobile_api(obj){
    const  token = localStorage.getItem("token")
    return API.post(`/verify-mobile`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}

async function updateOrgan_api(obj){
    const  token = localStorage.getItem("token")
    return API.post(`/set-auth`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}


async function getModes_api(){
    const  token = localStorage.getItem("token")
    return API.get(`/user/mode`,{
        headers:{
            'x-access-token':token
        }
    })
}

async function payFactor_api(obj){
    const  token = localStorage.getItem("token")
    return API.post(`/pay-factor`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}

async function payCharge_api(obj){
    const  token = localStorage.getItem("token")
    return API.post(`/pay-charge`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}


export async function getAllCountMediaApi(){
    const  token = localStorage.getItem("token")
    return API.post(`/report/media-all-count`,{},{
        headers:{
            'x-access-token':token
        }
    })
}



export async function mediaCount_api(groupId=null,start=null,end=null){
    const  token = localStorage.getItem("token")
    return API.post(`/report/media-count`,{
        groupId:groupId,
        start:start,
        end:end
    },{
        headers:{
            'x-access-token':token
        }
    })
}


async function dashboard_api(obj){
    const  token = localStorage.getItem("token")
    return API.post(`/report/dashboard`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}





export async function getCurrentEmotion_api(){
    const  token = localStorage.getItem("token")
    return API.get(`/user/current-emotions-panel`,{
        headers:{
            'x-access-token':token
        }
    })
}


export async function getSatisfaction_api(){
    const  token = localStorage.getItem("token")
    return API.get(`/satisfaction-panel`,{
        headers:{
            'x-access-token':token
        }
    })
}



export const getOperationChartApi = (groupId=null,start=null,end=null)=>{
    const token = localStorage.getItem("token")
    return API.post(`/user/chart-operation-panel`,{
        groupId:groupId,
        start:start,
        end:end
    },{
        headers:{
            "x-access-token":token
        }
    })
}



export const imageUploadApi = (obj,userId,oldImage=null)=>{
    const token = localStorage.getItem("token")

    let genId = userId+"_"+uniqid()

    const formData = new FormData();


    // Update the formData object
    formData.append("image", obj, obj.name);
    formData.append("userLink", genId);
    formData.append("oldImage", oldImage);

    return API.post(`/users-upload-image`,formData,{
        headers:{
            'x-access-token':token
        }
    })
}

export const imagePackageUploadApi = (obj,packageId,oldImage=null)=>{
    const token = localStorage.getItem("token")

    let genId = packageId+"_"+uniqid()
    const formData = new FormData();

    // Update the formData object
    formData.append("image", obj, obj.name);
    formData.append("packageLink", genId);
    formData.append("oldImage", oldImage);

    return API.post(`/package-upload-image`,formData,{
        headers:{
            'x-access-token':token
        }
    })
}



export const imageChallengeAdminUploadApi = (obj,packageId,oldImage=null)=>{
    const token = localStorage.getItem("token")

    // let genId = packageId+"_"+uniqid()
    let genId = packageId
    const formData = new FormData();

    // Update the formData object
    formData.append("image", obj, obj.name);
    formData.append("challengeLink", genId);
    formData.append("oldImage", oldImage);

    return API.post(`/admin/challenge-upload-image`,formData,{
        headers:{
            'x-access-token':token
        }
    })
}



async function sendWelcomeMessage_api(userId){
    const  token = localStorage.getItem("token")
    return API.post(`/user-welcome`,{userId},{
        headers:{
            'x-access-token':token
        }
    })
}

async function sendWelcomeMessageAll_api(){
    const  token = localStorage.getItem("token")
    return API.post(`/user-welcome-all`,{},{
        headers:{
            'x-access-token':token
        }
    })
}

 async function getBuyCount_api(){
    const  token = localStorage.getItem("token")
    return API.get(`/buy-count`,{
        headers:{
            'x-access-token':token
        }
    })
}
async function getTicketCount_api(){
    const  token = localStorage.getItem("token")
    return API.get(`/tickets-unread-count`,{
        headers:{
            'x-access-token':token
        }
    })
}

async function getTickets_api(page =0){
    const  token = localStorage.getItem("token")
    return API.get(`/tickets`,{
        params:{
            page:page
        },
        headers:{
            'x-access-token':token
        }
    })
}

//admin

async function getRegistersAdmin_api(page =0){
    const  token = localStorage.getItem("token")
    return API.get(`/admin/registers`,{
        params:{
            page:page
        },
        headers:{
            'x-access-token':token
        }
    })
}


async function getTicketsAdmin_api(page =0){
    const  token = localStorage.getItem("token")
    return API.get(`/admin/tickets`,{
        params:{
            page:page
        },
        headers:{
            'x-access-token':token
        }
    })
}


async function sendAdminRegisterUserForm_api(id){
    const  token = localStorage.getItem("token")
    return API.post(`/admin/send-users-form-message`,{
        registerId:id
    },{
        headers:{
            'x-access-token':token
        }
    })
}


async function sendAdminRegisterResponse_api(id,text,status){
    const  token = localStorage.getItem("token")
    return API.post(`/admin/registers/${id}/response`,{
        description:text,
        status:status
    },{
        headers:{
            'x-access-token':token
        }
    })
}


async function sendAdminResponse_api(id,text){
    const  token = localStorage.getItem("token")
    return API.post(`/admin/tickets/${id}/response`,{
        description:text
    },{
        headers:{
            'x-access-token':token
        }
    })
}



async function sendResponse_api(id,text){
    const  token = localStorage.getItem("token")
    return API.post(`/tickets/${id}/response`,{
        description:text
    },{
        headers:{
            'x-access-token':token
        }
    })
}


//admin

async function getRegistersAdminDetail_api(id){
    const  token = localStorage.getItem("token")
    return API.get(`/admin/registers/${id}`,{
        headers:{
            'x-access-token':token
        }
    })
}


async function getTicketsAdminDetail_api(id){
    const  token = localStorage.getItem("token")
    return API.get(`/admin/tickets/${id}`,{
        headers:{
            'x-access-token':token
        }
    })
}


async function getTicketsDetail_api(id){
    const  token = localStorage.getItem("token")
    return API.get(`/tickets/${id}`,{
        headers:{
            'x-access-token':token
        }
    })
}

async function openRegisterAdmin_api(id){
    const  token = localStorage.getItem("token")
    return API.put(`/admin/registers/${id}/open`,{},{
        headers:{
            'x-access-token':token
        }
    })
}


async function openTicketAdmin_api(id){
    const  token = localStorage.getItem("token")
    return API.put(`/admin/tickets/${id}/open`,{},{
        headers:{
            'x-access-token':token
        }
    })
}

async function openTicket_api(id){
    const  token = localStorage.getItem("token")
    return API.put(`/tickets/${id}/open`,{},{
        headers:{
            'x-access-token':token
        }
    })
}

//admin

async function closeRegistersAdmin_api(id){
    const  token = localStorage.getItem("token")
    return API.put(`/admin/registers/${id}/close`,{},{
        headers:{
            'x-access-token':token
        }
    })
}


async function closeTicketAdmin_api(id){
    const  token = localStorage.getItem("token")
    return API.put(`/admin/tickets/${id}/close`,{},{
        headers:{
            'x-access-token':token
        }
    })
}

async function closeTicket_api(id){
    const  token = localStorage.getItem("token")
    return API.put(`/tickets/${id}/close`,{},{
        headers:{
            'x-access-token':token
        }
    })
}


//admin

async function createRegistersAdmin_api(obj){
    const  token = localStorage.getItem("token")
    return API.post(`/admin/registers`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}

async function createTicketsAdmin_api(obj){
    const  token = localStorage.getItem("token")
    return API.post(`/admin/tickets`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}


async function createTickets_api(obj){
    const  token = localStorage.getItem("token")
    return API.post(`/tickets`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}

async function getRegister_api(id){
    const  token = localStorage.getItem("token")
    return API.get(`/admin/registers-with-code/${id}`,{
        headers:{
            'x-access-token':token
        }
    })
}

export async function getPositiveKpi_api(id){
    const  token = localStorage.getItem("token")
    return API.get(`/kpi-positive`,{
        headers:{
            'x-access-token':token
        }
    })
}

export async function getNegativeKpi_api(id){
    const  token = localStorage.getItem("token")
    return API.get(`/kpi-negative`,{
        headers:{
            'x-access-token':token
        }
    })
}



async function sendUsersRegisterForm_api(users,groups,code){
    const  token = localStorage.getItem("token")
    return API.post(`/admin/send-users-form`,{users,groups,code},{
        headers:{
            'x-access-token':token
        }
    })
}



async function getWinners_api(id){
    const  token = localStorage.getItem("token")
    return API.post(`/admin/challenge-winners`,{challengeId:id},{
        headers:{
            'x-access-token':token
        }
    })
}

async function setWinners_api(id,obj){
    const  token = localStorage.getItem("token")
    return API.put(`/admin/set-winners/${id}`,obj,{
        headers:{
            'x-access-token':token
        }
    })
}

 const getEmotionCurrentList = (groups=[])=>{
    const token = localStorage.getItem("token")
    return API.post(`/user/current-emotions`,{groups:groups},{
        headers:{
            "x-access-token":token
        }
    })
}


 const statisticOfOperationApi = (groups=[])=>{
    const token = localStorage.getItem("token")
    let startYear = moment(new Date()).format("jYYYY")
    let intYear = parseInt(startYear)

    let stringDate  = `${startYear}-01-01`
    let endDate  = `${intYear+1}-01-01`

    let startMiladi = moment(stringDate,"jYYYY-jMM-jDD").format("YYYY-MM-DD")
    let endMiladi = moment(endDate,"jYYYY-jMM-jDD").format("YYYY-MM-DD")

    return API.post(`/user/statistic-operation`,{
        groupId:"61563b85a0a2041374ac5efe",
        groups:groups,
        start:startMiladi,
        end:endMiladi
    },{
        headers:{
            "x-access-token":token
        }
    })
}

export const getGroupsApi = ()=>{
    const token = localStorage.getItem("token")
    return API.get(`/user/report-groups`,{
        headers:{
            "x-access-token":token
        }
    })
}

export const getKpiMainApi = (groupId=null,start=null,end=null)=>{
    const token = localStorage.getItem("token")
    return API.post(`/user/kpi-main-report-client`,{
        groupId:groupId,
        start:start,
        end:end
    },{
        headers:{
            "x-access-token":token
        }
    })
}

export const getKpiDetail_api = (groupId=null,start=null,end=null,kpiId=null)=>{
    const token = localStorage.getItem("token")
    return API.post(`/user/kpi-main-report-detail-client`,{
        groupId:groupId,
        start:start,
        end:end,
        kpiId:kpiId
    },{
        headers:{
            "x-access-token":token
        }
    })
}


export {
    statisticOfOperationApi,
    getEmotionCurrentList,
    setWinners_api,
    getWinners_api,
    sendUsersRegisterForm_api,
    openTicketAdmin_api,
    closeTicketAdmin_api,
    sendAdminResponse_api,
    getTicketsAdminDetail_api,
    createTicketsAdmin_api,
    deleteTicketAdmin_api,
    getTicketsAdmin_api,
    getTicketCount_api,
    deleteTicket_api,
    openTicket_api,
    closeTicket_api,
    sendResponse_api,
    getTicketsDetail_api,
    createTickets_api,
    getTickets_api,
    dashboard_api,
    payFactor_api,
    getModes_api,
    login_api,
    verify_api,
    setOrganName_api,
    getGroups_api,
    createGroup_api,
    updateGroup_api,
    deleteGroups_api,
    createUser_api,
    getUsers_api,
    updateUser_api,
    deleteUser_api,
    getPackages_api,
    createPackage_api,
    updatePackage_api,
    deletePackage_api,
    getOrgan_api,
    getMedias_api,
    deleteMedia_api,
    getUserModes_api,
    payCharge_api,
    updateMobile_api,
    verifyMobile_api,
    updateOrgan_api,
    login_username_api,
    getPlan_api,
    getOperationsReport_api,
    getOperationsUserReport_api,
    getOperationsSumReport_api,
    sendWelcomeMessage_api,
    sendWelcomeMessageAll_api,
    getAllUsers_api,
    getBuys_api,
    changeBuyStatus_api,
    getBuyCount_api,
    getOrganAdmin_api,
    updateOrganAdmin_api,
    createOrganAdmin_api,
    getChallengeAdmin_api,
    updateChallengeAdmin_api,
    createChallengeAdmin_api,
    deleteChallengeAdmin_api,
    createRegistersAdmin_api,
    deleteRegistersAdmin_api,
    getRegistersAdmin_api,
    sendAdminRegisterResponse_api,
    getRegistersAdminDetail_api,
    openRegisterAdmin_api,
    closeRegistersAdmin_api,
    updateRegistersAdmin_api,
    sendAdminRegisterUserForm_api,
    getRegister_api,
    getKpiPackage_api
}
