import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function ProtectedMainRoute ({component: Component, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => localStorage.getItem('token')
                ? <Redirect to={{pathname: '/panel/groups', state: {from: props.location}}} />
                : <Redirect to={{pathname: '/login', state: {from: props.location}}} />
            }
        />
    )
}

export default ProtectedMainRoute;
