import React, {Suspense } from 'react';
import './App.css';
import "./Main.scss";


import {Route, withRouter} from "react-router-dom";
import Login from "./Pages/Login/Login";
import ProtectedMainRoute from "./Components/ProtectedMainRoute";
import Preload from "./Components/Preload";


//Protected Routes
const Panel = React.lazy(() => import('./Pages/Panel/Panel'));
const Visit = React.lazy(() => import('./Pages/Visit/Visit'));
const UsersForm = React.lazy(() => import('./Pages/UsersForm/UsersForm'));







function App() {

    return (
        <>
            <Suspense fallback={<Preload/>}>
            <div className="main_container" id="App">

                {/*Home*/}
                <ProtectedMainRoute path='/' exact component={Panel} />

                {/*Panel*/}
                <Route path='/panel'  component={Panel} />
                <Route path='/login'  component={Login} />
                <Route path='/visit'  component={Visit} />
                <Route path='/users-form/:code'  component={UsersForm} />
            </div>
            </Suspense>
        </>
    );
}

export default withRouter(App);
