export const Setup ={
    endpoint:"https://api.starium-space.com",
    //endpoint:"http://localhost:4000",
    filePoint:"https://media.starium-space.com/",
    ImagePanelPoint:"https://media.starium-space.com/static_images/panel/",
    ImageClientPoint:"https://media.starium-space.com/static_images/client/",
    payment_endpoint:process.env.REACT_APP_API_PAYMENT_ENDPOINT,
    version:process.env.REACT_APP_API_VERSION,
    default_malls:"ww8Sa3xrPp",
    mqtt:process.env.REACT_APP_MQTT,

}
