import React from "react"
import {Setup} from "../Services/Setup";
// import mainLogo from "../assets/images/main_logo.svg";
// import preload from "../assets/images/preload.gif";

const mainLogo = Setup.ImagePanelPoint+"images/main_logo.svg";
const preload = Setup.ImagePanelPoint+"images/preload.gif";

const Preload = ()=>{
    return(
        <div className="preload">
            <img src={mainLogo} alt=""/>
            <img src={preload} alt="" style={{marginTop:20,marginLeft: 50}}/>
        </div>
    )
}

export default Preload;