import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// "Inline" English and Arabic translations.
// We can localize to any language and any number of languages.
const resources = {
    en: {
        translation: {
            app_name: "Grootbasket",

            SIDE_NAV_DASHBOARD :"Dashboard",
            SIDE_NAV_GROUPS :"Groups",
            SIDE_NAV_PACKAGES :"Packages",
            SIDE_NAV_USERS :"Users",
            SIDE_NAV_OPERATION :"Operations",
            SIDE_NAV_MEDIA :"Media",
            SIDE_NAV_EMOTION :"Emotions",
            SIDE_NAV_CHARGE :"Account",
            SIDE_NAV_SETTING :"Settings",
            SIDE_NAV_LOGOUT :"Logout",


            DASHBOARD_RECHARGE_BTN:"Recharge Account",
            DASHBOARD_RECHARGE_ACTIVE_STATUS:"Active",
            DASHBOARD_RECHARGE_INACTIVE_STATUS:"Inactive",

            DASHBOARD_EMOTION_COUNT_PERSON:"Person",
            DASHBOARD_MEDIA_GIFT:"Gift",
            DASHBOARD_MEDIA_GOLD_STAR:"Gold Star",
            DASHBOARD_MEDIA_RED_STAR:"Red Star",
            DASHBOARD_MEDIA_POLL:"Poll",
            DASHBOARD_MEDIA_NOTIFICATION:"Notification",
            DASHBOARD_MEDIA_COUNT:"Count",
            DASHBOARD_CHART_GOLD:"Gold",
            DASHBOARD_CHART_RED:"red",
            DASHBOARD_TOP_GROUP:"Top Groups",
            DASHBOARD_TOP_PERSON:"Top Persons",
            DASHBOARD_CHARGE_LEFT_DAY_COUNT:"Left Days ",
            DASHBOARD_CHARGE_GROUPS_COUNT:"Groups Count",
            DASHBOARD_CHARGE_PACKAGE_COUNT:"Buys Count",
            DASHBOARD_CHARGE_USERS_COUNT:"Person Count",

            GROUPS_MESSAGE_EDIT_SUCCESSFULLY : "Group Successfully Edited",
            GROUPS_MESSAGE_REMOVE_SUCCESSFULLY : "Group Successfully Deleted",
            GROUPS_MESSAGE_ADD_SUCCESSFULLY : "Group Successfully Created",
            GROUPS_MESSAGE_ERROR : "Error",
            GROUPS_MESSAGE_LIMIT_CREATE_GROUPS : "Limit In Create Group",
            GROUPS_MESSAGE_ERROR_CREATE_NAME : "Please Enter Group Name",
            GROUPS_TITLE_GROUPS : "GROUPS",
            GROUPS_HEADER_DESC : "You Can Manage Your Groups In This Page",
            GROUPS_TABLE_COL_NAME : "Name",
            GROUPS_TABLE_COL_STATUS : "Status",
            GROUPS_TABLE_COL_ADD_EDIT : "edit /remove",
            GROUPS_TABLE_COL_ACTIVE : "active",
            GROUPS_TABLE_COL_DE_ACTIVE : "inactive",
            GROUPS_TABLE_DELETE_ALERT : "All Group Data Was Deleted After Delete Group",
            GROUPS_TABLE_DELETE_ACCEPT : "Delete",
            GROUPS_TABLE_DELETE_CANCEL : "Cancel",
            GROUPS_ADD_BTN : "Add",
            GROUPS_MODAL_TITLE : "Create Group",
            GROUPS_MODAL_ACCESS : "Access",
            GROUPS_MODAL_NAME_PLACEHOLDER : "Group Name",
            GROUPS_MODAL_ACCESS_GENERATE : "Generate",
            GROUPS_MODAL_ACCESS_TRANSFER : "Transfer",
            GROUPS_MODAL_ACCESS_WARN : "Warn",
            GROUPS_MODAL_ACCESS_REPORT : "Report",
            GROUPS_MODAL_ACCESS_GIFT : "Gift",
            GROUPS_MODAL_ACCESS_POLL : "Poll",
            GROUPS_MODAL_ACCESS_NOTIFICATION : "Notification",
            GROUPS_MODAL_ACCESS_ANONYMOUS : "Anonymous",
            GROUPS_MODAL_EDIT_BTN : "Edit",
            GROUPS_MODAL_ADD_BTN : "Submit",



            PACKAGES_MESSAGE_ERROR : "Error",
            PACKAGES_MESSAGE_DELETE_SUCCESSFULLY : "Package Successfully Deleted",
            PACKAGES_MESSAGE_ADD_SUCCESSFULLY : "Package Successfully Created",
            PACKAGES_MESSAGE_EDIT_SUCCESSFULLY : "Package Successfully Edited",
            PACKAGES_MESSAGE_ERROR_SEND_IMAGE : "Image Upload Is Failed",
            PACKAGES_TITLE_PACKAGES : "Packages",
            PACKAGES_HEADER_DESC : "You Can Manage Your Buys In This Page",
            PACKAGES_TABLE_COL_TITLE : "Title",
            PACKAGES_TABLE_COL_VALUE : "Value",
            PACKAGES_TABLE_COL_STACK : "Stack",
            PACKAGES_TABLE_COL_ADD_EDIT : "edit /remove",
            PACKAGES_TABLE_COL_ACTIVE : "Active",
            PACKAGES_TABLE_COL_DE_ACTIVE : "Inactive",
            PACKAGES_TABLE_DELETE_ALERT : "All Package Data Was Deleted After Delete Package",
            PACKAGES_TABLE_DELETE_ACCEPT : "Delete",
            PACKAGES_TABLE_DELETE_CANCEL : "Cancel",
            PACKAGES_ADD_BTN : "Add",
            PACKAGES_MODAL_TITLE : "Create Buys",
            PACKAGES_FORM_NAME : "Name",
            PACKAGES_FORM_VALUE : "Value",
            PACKAGES_FORM_STACK : "Stack",
            PACKAGES_FORM_DESCRIPTION : "Description",
            PACKAGES_FORM_AFTER_BUY : "message after buy",
            PACKAGES_FORM_ONE_USE : "Ability To One Buy",
            PACKAGES_FORM_GROUPS : "Groups",
            PACKAGES_FORM_SELECT_IMAGE : "Select Image",
            PACKAGES_MODAL_NAME_PLACEHOLDER : "Group Name",
            PACKAGES_MODAL_EDIT_BTN : "Edit",
            PACKAGES_MODAL_ADD_BTN : "Add",



            USERS_MESSAGE_ERROR : "Error",
            USERS_MESSAGE_DELETE_SUCCESSFULLY : "User Deleted Successfully",
            USERS_MESSAGE_ADD_SUCCESSFULLY : "User Created Successfully",
            USERS_MESSAGE_EDIT_SUCCESSFULLY : "User Edited Successfully",
            USERS_MESSAGE_ERROR_SEND_IMAGE : "Image Upload Is Failed",
            USERS_MESSAGE_ERROR_SIZE_IMAGE : "Image Size Is Grater Than 50K ",
            USERS_TITLE_USERS : "Users",
            USERS_HEADER_DESC : "You Can Manage Your Users In This Page",
            USERS_TABLE_COL_NAME : "NAME",
            USERS_TABLE_COL_FAMILY : "FAMILY",
            USERS_TABLE_COL_MOBILE : "MOBILE",
            USERS_TABLE_COL_ADD_EDIT : "add / remove",
            USERS_TABLE_COL_ACTIVE : "active",
            USERS_TABLE_COL_DE_ACTIVE : "inactive",
            USERS_TABLE_DELETE_ALERT : "All User Data Was Deleted After Delete User",
            USERS_TABLE_DELETE_ACCEPT : "Delete",
            USERS_TABLE_DELETE_CANCEL : "Cancel",
            USERS_ADD_BTN : "Add",
            USERS_MODAL_TITLE : "Create User",
            USERS_FORM_NAME : "Name",
            USERS_FORM_FAMILY : "Family",
            USERS_FORM_MOBILE : "Mobile",
            USERS_FORM_GROUPS : "Groups",
            USERS_FORM_SELECT_IMAGE : "Select Image",
            USERS_MODAL_NAME_PLACEHOLDER : "Group Name",
            USERS_MODAL_EDIT_BTN : "Edit",
            USERS_MODAL_ADD_BTN : "Submit",



            OPERATION_TITLE_OPERATION : "Operations",
            OPERATION_HEADER_DESC : "You Can See KpiReport of Your Persons In This Page",
            OPERATION_REPORT_BTN : "Report",
            OPERATION_REPORT_ALL_GROUPS : "All Groups",
            OPERATION_REPORT_TO : "To",
            OPERATION_TABLE_COL_FULL_NAME : "Full Name",
            OPERATION_TABLE_COL_GOLD_STAR : "Gold Star",
            OPERATION_TABLE_COL_RED_STAR : "Red Star",
            OPERATION_TABLE_COL_DETAIL : "Detail",

            EMOTIONS_TITLE_EMOTIONS : "Emotions",
            EMOTIONS_HEADER_DESC : "You Can See KpiReport of Your Persons In This Page",
            EMOTIONS_REPORT_BTN : "Report",
            EMOTIONS_REPORT_ALL_GROUPS : "All Groups",
            EMOTIONS_REPORT_TO : "To",
            EMOTIONS_TABLE_COL_FULL_NAME : "Full Name",
            EMOTIONS_TABLE_COL_GOLD_HAPPY : "Happy",
            EMOTIONS_TABLE_COL_RED_SAD : "Sad",
            EMOTIONS_TABLE_COL_NORMAL : "Normal",
            EMOTIONS_TABLE_COL_ANGRY : "Angry",
            EMOTIONS_TABLE_COL_DETAIL : "Detail",


            MEDIA_TYPE_TITLE_CHAL :"Challenge",
            MEDIA_TYPE_TITLE_GIFT :"Gift",
            MEDIA_TYPE_TITLE_MEET :"Meet",
            MEDIA_TYPE_TITLE_NOTIFICATION :"Notification",
            MEDIA_TYPE_TITLE_POLL :"Poll",
            MEDIA_TYPE_TITLE_WELCOME :"Welcome",
            MEDIA_TITLE_MEDIA : "Medias",
            MEDIA_HEADER_DESC : "You Can See Medias of Your Persons In This Page",
            MEDIA_TABLE_COL_TYPE : "Type",
            MEDIA_TABLE_COL_SENDER : "Sender",
            MEDIA_TABLE_COL_DATE : "Date",
            MEDIA_TABLE_COL_DELETE : "Delete",
            MEDIA_TABLE_DELETE_ALERT : "All Media Data Was Deleted After Delete Media",
            MEDIA_TABLE_DELETE_ACCEPT : "Delete",
            MEDIA_TABLE_DELETE_CANCEL : "Cancel",

            ACCOUNT_MESSAGE_USER_IN_USE : "users used more than requested users",
            ACCOUNT_MESSAGE_USER_DEC_ERROR : "you cant dec users before expire time",

            ACCOUNT_MESSAGE_GROUP_IN_USE : "groups used more than requested groups",
            ACCOUNT_MESSAGE_GROUP_DEC_ERROR : "you cant dec groups before expire time",

            ACCOUNT_MESSAGE_PACKAGE_IN_USE : "package used more than requested package",
            ACCOUNT_MESSAGE_PACKAGE_DEC_ERROR : "you cant dec package before expire time",

            ACCOUNT_MESSAGE_TOTAL_PRICE_IS_ZERO : "total price is zero",

            ACCOUNT_TITLE_MEDIA : "Medias",
            ACCOUNT_HEADER_DESC : "You Can See Medias of Your Persons In This Page",
            ACCOUNT_HEADER_ADD_BTN: "Charge Account",
            ACCOUNT_HEADER_UPGRADE_BTN: "Upgrade Account",
            ACCOUNT_BODY_TITLE: "Account",
            ACCOUNT_LEFT_ONE: "Dear User You Need To Charge Account After",
            ACCOUNT_LEFT_TWO: "Days",
            ACCOUNT_MILKY_DESC: "پلن آندرومدا برای شرکت ها و سازمانهایی با سایز متوسط با اندازه کارمندان از 50 تا 100 مناسب میباشد . و دارای امکاناتی از قبیل ساخت نظرسنجی , ارسال اطلاعیه , ارسال گیفت کارت , انتقال ستاره , دادن اخطار , حال کارمندان سازمان , گزارش های پیشرفته ... میباشد , با ما سازمان خود را زنده نگه دارید",
            ACCOUNT_MODAL_TITLE: "Account",
            ACCOUNT_MODAL_PERSON_UNIT: "Person",
            ACCOUNT_MODAL_DAY: "Day",
            ACCOUNT_MODAL_MONTH: "Month",
            ACCOUNT_MODAL_YEAR: "Year",
            ACCOUNT_MODAL_PRICE_UNIT: "Toman",
            ACCOUNT_MODAL_PAYABLE_AMOUNT: "Payable Amount",
            ACCOUNT_MODAL_PAY_BTN: "Pay",
            ACCOUNT_MODAL_ROW_LEFT_DAYS: "Left Days",
            ACCOUNT_MODAL_ROW_LEFT_DAYS_COST: "Left Days Cost",
            ACCOUNT_MODAL_ROW_PLAN_COST: "Selected Plan Cost",
            ACCOUNT_MODAL_ROW_PLAN_DURATION: "Selected Plan Duration",



            SETTING_MESSAGE_EDIT_ERROR : "Problem With Edit",
            SETTING_MESSAGE_ORGAN_SUCCESSFULLY_EDITED : "Organ Education Edit Successfully",
            SETTING_MESSAGE_WRONG_CODE : "Verify Code Is Wrong",
            SETTING_MESSAGE_ENTER_CODE : "Please Enter Verify Code",
            SETTING_MESSAGE_ENTER_MOBILE : "Please Enter Mobile Number",
            SETTING_MESSAGE_PROBLEM_SEND_CODE : "Problem With Send Code",
            SETTING_MESSAGE_SEND_CODE : "Verify Code Send To Your Mobile Number",
            SETTING_MESSAGE_MOBILE_CHANGE_SUCCESSFULLY : "Change Mobile Number Successfully",
            SETTING_CHANGE_MOBILE_NUMBER : "Change Mobile Number",
            SETTING_ENTER_NEW_MOBILE_NUMBER : "Please Enter New Mobile Number",
            SETTING_ENTER_CODE : "Please Enter Verify Code",
            SETTING_APPROVE_CODE : "Code Approve",
            SETTING_SEND_VERIFY_CODE : "Send Verify Code",

            SETTING_TITLE_MEDIA : "Settings",
            SETTING_HEADER_DESC : "You Can Change Settings Of Your Organ At This Page",
            SETTING_BODY_TITLE : "Base Info",
            SETTING_ORGAN_NAME : "Organ Name",
            SETTING_BODY_LOGIN_INFO : "UsersForm Information",
            SETTING_USERNAME : "User Name",
            SETTING_PASSWORD : "Password",
            SETTING_MOBILE : "Mobile Number",
            SETTING_MOBILE_PLACE_HOLDER : "Mobile Number For Authentication",
            SETTING_MOBILE_CHANGE : "Change Mobile Number",
            SETTING_SAVE_BTN : "Save Changes",



            LOGIN_HEADER_MENU_SUPPORT:"Support",
            LOGIN_HEADER_MENU_LEARN:"Learn",
            LOGIN_HEADER_MENU_WEBSITE:"WebSite",

            LOGIN_FORM_TITLE_LOGIN_WITH_USERNAME:"UsersForm With Username",
            LOGIN_FORM_TITLE_LOGIN_WITH_MOBILE:"UsersForm With Mobile",
            LOGIN_FORM_FILED_MOBILE:"Mobile Number",
            LOGIN_FORM_FILED_USERNAME:"Username",
            LOGIN_FORM_FILED_ORGAN_NAME:"Organ Name",
            LOGIN_FORM_FILED_PASSWORD:"Password",
            LOGIN_FORM_SUBMIT_BTN:"Login",
            LOGIN_FORM_SEND_CODE_BTN:"Send Code",
            LOGIN_FORM_TITLE_CODE:"Verify Code",


            LOGIN_MESSAGE_USERNAME : "Please Enter Username",
            LOGIN_MESSAGE_PASSWORD : "Please Enter Password",
            LOGIN_MESSAGE_MOBILE : "Please Enter Mobile Number",
            LOGIN_MESSAGE_WRONG_USER_PASSWORD : "Password Is Wrong",
            LOGIN_MESSAGE_WRONG_MOBILE : "Mobile Number Is Wrong",
            LOGIN_MESSAGE_WRONG_MOBILE_CODE : "Verify Code Is Wrong",
            LOGIN_MESSAGE_SEND_CODE : "Verify Code Send To Your Mobile Number",
            LOGIN_MESSAGE_ENTER_CODE : "Please Enter Verify Code",
            LOGIN_MESSAGE_ENTER_MOBILE : "Please Enter Mobile Number",
            LOGIN_MESSAGE_ENTER_ORGAN_NAME : "Please Enter Organ Name",
            LOGIN_MESSAGE_WRONG_ORGAN_NAME : "Organ Name Is Wrong",


        },
    },
    fa: {
        translation: {

            SIDE_NAV_DASHBOARD :"داشبورد",
            SIDE_NAV_GROUPS :"گروه ها",
            SIDE_NAV_PACKAGES :"پکیج ها",
            SIDE_NAV_USERS :"کاربران",
            SIDE_NAV_OPERATION :"عملکرد",
            SIDE_NAV_MEDIA :"مدیا",
            SIDE_NAV_EMOTION :"احساسات",
            SIDE_NAV_CHARGE :"اکانت",
            SIDE_NAV_SETTING :"تنظیمات",
            SIDE_NAV_LOGOUT :"خروج",

            DASHBOARD_RECHARGE_BTN:"تمدید اعتبار",
            DASHBOARD_RECHARGE_ACTIVE_STATUS:"فعال",
            DASHBOARD_RECHARGE_INACTIVE_STATUS:"غیرفعال",



            DASHBOARD_EMOTION_COUNT_PERSON:"نفر",
            DASHBOARD_MEDIA_GIFT:"هدیه",
            DASHBOARD_MEDIA_GOLD_STAR:"ستاره طلایی",
            DASHBOARD_MEDIA_RED_STAR:"ستاره قرمز",
            DASHBOARD_MEDIA_POLL:"نظرسنجی",
            DASHBOARD_MEDIA_NOTIFICATION:"اطلاعیه",
            DASHBOARD_MEDIA_COUNT:"عدد",

            DASHBOARD_CHART_GOLD:"طلایی",
            DASHBOARD_CHART_RED:"قرمز",

            DASHBOARD_TOP_GROUP:"گروههای برتر",
            DASHBOARD_TOP_PERSON:"نفرات برتر",

            DASHBOARD_CHARGE_LEFT_DAY_COUNT:"روزهای مانده",
            DASHBOARD_CHARGE_GROUPS_COUNT:"تعداد گروهها",
            DASHBOARD_CHARGE_PACKAGE_COUNT:"تعداد پکیج ها",
            DASHBOARD_CHARGE_USERS_COUNT:"تعداد کاربران",



            GROUPS_MESSAGE_EDIT_SUCCESSFULLY : "گروه با موفقیت ویرایش شد",
            GROUPS_MESSAGE_REMOVE_SUCCESSFULLY : "گروه با موفقیت حذف شد",
            GROUPS_MESSAGE_ADD_SUCCESSFULLY : "گروه با موفقیت ثبت شد",
            GROUPS_MESSAGE_ERROR : "مشکلی ایجاد شده است",
            GROUPS_MESSAGE_LIMIT_CREATE_GROUPS : "تعداد ایجاد گروه مجاز به اتمام رسیده است",
            GROUPS_MESSAGE_ERROR_CREATE_NAME : "لطفا نام گروه را وارد نمایید",
            GROUPS_TITLE_GROUPS : "گروهها",
            GROUPS_HEADER_DESC : "شما در این قسمت میتوانید گروه های خود را ایجاد و مدیریت کنید",
            GROUPS_TABLE_COL_NAME : "نام",
            GROUPS_TABLE_COL_STATUS : "وضعیت",
            GROUPS_TABLE_COL_ADD_EDIT : "ویرایش / حذف",
            GROUPS_TABLE_COL_ACTIVE : "فعال",
            GROUPS_TABLE_COL_DE_ACTIVE : "غیرفعال",
            GROUPS_TABLE_DELETE_ALERT : "با پاک شدن گروه تمام اطلاعات گروه از بین میرود",
            GROUPS_TABLE_DELETE_ACCEPT : "پاک شود",
            GROUPS_TABLE_DELETE_CANCEL : "منصرف شدم",
            GROUPS_ADD_BTN : "اضافه کردن",
            GROUPS_MODAL_TITLE : "ایجاد گروه",
            GROUPS_MODAL_ACCESS : "دسترسی ها",
            GROUPS_MODAL_NAME_PLACEHOLDER : "نام گروه",

            GROUPS_MODAL_ACCESS_GENERATE : "تولید",
            GROUPS_MODAL_ACCESS_TRANSFER : "انتقال",
            GROUPS_MODAL_ACCESS_WARN : "اخطار",
            GROUPS_MODAL_ACCESS_REPORT : "گزارش",
            GROUPS_MODAL_ACCESS_GIFT : "هدیه",
            GROUPS_MODAL_ACCESS_POLL : "نظرسنجی",
            GROUPS_MODAL_ACCESS_NOTIFICATION : "اطلاعیه",
            GROUPS_MODAL_ACCESS_ANONYMOUS : "ناشناس",

            GROUPS_MODAL_EDIT_BTN : "ویرایش",
            GROUPS_MODAL_ADD_BTN : "ثبت",




            PACKAGES_MESSAGE_ERROR : "مشکلی ایجاد شده است",
            PACKAGES_MESSAGE_DELETE_SUCCESSFULLY : "پکیج با موفقیت حذف شد",
            PACKAGES_MESSAGE_ADD_SUCCESSFULLY : "پکیج با موفقیت ایجاد شد",
            PACKAGES_MESSAGE_EDIT_SUCCESSFULLY : "پکیج با موفقیت ویرایش شد",
            PACKAGES_MESSAGE_ERROR_SEND_IMAGE : "در ارسال تصویر  مشکلی پیش آمده است",

            PACKAGES_TITLE_PACKAGES : "پکیج ها",
            PACKAGES_HEADER_DESC : "شما در این قسمت میتوانید پکیج های خود را ایجاد و مدیریت کنید",
            PACKAGES_TABLE_COL_TITLE : "عنوان",
            PACKAGES_TABLE_COL_VALUE : "ارزش",
            PACKAGES_TABLE_COL_STACK : "موجودی",
            PACKAGES_TABLE_COL_ADD_EDIT : "ویرایش / حذف",
            PACKAGES_TABLE_COL_ACTIVE : "فعال",
            PACKAGES_TABLE_COL_DE_ACTIVE : "غیرفعال",
            PACKAGES_TABLE_DELETE_ALERT : "با پاک شدن پکیج تمام اطلاعات پکیج از بین میرود",
            PACKAGES_TABLE_DELETE_ACCEPT : "پاک شود",
            PACKAGES_TABLE_DELETE_CANCEL : "منصرف شدم",
            PACKAGES_ADD_BTN : "اضافه کردن",
            PACKAGES_MODAL_TITLE : "ایجاد پکیج",
            PACKAGES_FORM_NAME : "نام",
            PACKAGES_FORM_VALUE : "ارزش",
            PACKAGES_FORM_STACK : "موجودی",
            PACKAGES_FORM_DESCRIPTION : "توضیحات",
            PACKAGES_FORM_AFTER_BUY : "پیام بعد از خرید",
            PACKAGES_FORM_ONE_USE : "قابلیت یکبار خرید",
            PACKAGES_FORM_GROUPS : "گروه ها",
            PACKAGES_FORM_SELECT_IMAGE : "انتخاب تصویر",
            PACKAGES_MODAL_NAME_PLACEHOLDER : "نام گروه",
            PACKAGES_MODAL_EDIT_BTN : "ویرایش",
            PACKAGES_MODAL_ADD_BTN : "ثبت",



            USERS_MESSAGE_ERROR : "مشکلی ایجاد شده است",
            USERS_MESSAGE_DELETE_SUCCESSFULLY : "کاربر با موفقیت حذف شد",
            USERS_MESSAGE_ADD_SUCCESSFULLY : "کاربر با موفقیت ایجاد شد",
            USERS_MESSAGE_EDIT_SUCCESSFULLY : "کاربر با موفقیت ویرایش شد",
            USERS_MESSAGE_ERROR_SEND_IMAGE : "در ارسال تصویر  مشکلی پیش آمده است",
            USERS_MESSAGE_ERROR_SIZE_IMAGE:"حجم تصویر از 50 کیلو بایت بیشتر است",
            USERS_TITLE_USERS : "کاربران",
            USERS_HEADER_DESC : "شما در این قسمت میتوانید کاربر های خود را ایجاد و مدیریت کنید",
            USERS_TABLE_COL_NAME : "نام",
            USERS_TABLE_COL_FAMILY : "نام خانوادگی",
            USERS_TABLE_COL_MOBILE : "موبایل",
            USERS_TABLE_COL_ADD_EDIT : "ویرایش / حذف",
            USERS_TABLE_COL_ACTIVE : "فعال",
            USERS_TABLE_COL_DE_ACTIVE : "غیرفعال",
            USERS_TABLE_DELETE_ALERT : "با پاک شدن کاربر تمام اطلاعات کاربر از بین میرود",
            USERS_TABLE_DELETE_ACCEPT : "پاک شود",
            USERS_TABLE_DELETE_CANCEL : "منصرف شدم",
            USERS_ADD_BTN : "اضافه کردن",
            USERS_MODAL_TITLE : "ایجاد کاربر",
            USERS_FORM_NAME : "نام",
            USERS_FORM_FAMILY : "نام خانوادگی",
            USERS_FORM_MOBILE : "موبایل",
            USERS_FORM_GROUPS : "گروه ها",
            USERS_FORM_SELECT_IMAGE : "انتخاب تصویر",
            USERS_MODAL_NAME_PLACEHOLDER : "نام گروه",
            USERS_MODAL_EDIT_BTN : "ویرایش",
            USERS_MODAL_ADD_BTN : "ثبت",




            OPERATION_TITLE_OPERATION : "عملکردها",
            OPERATION_HEADER_DESC : "شما در این قسمت میتوانید عملکرد نیروهای خود را ببینید",
            OPERATION_REPORT_BTN : "گزارش",
            OPERATION_REPORT_ALL_GROUPS : "همه گروهها",
            OPERATION_REPORT_TO : "تا",
            OPERATION_TABLE_COL_FULL_NAME : "نام و نام خانوادگی",
            OPERATION_TABLE_COL_GOLD_STAR : "ستاره طلایی",
            OPERATION_TABLE_COL_RED_STAR : "ستاره قرمز",
            OPERATION_TABLE_COL_DETAIL : "جزییات",


            EMOTIONS_TITLE_EMOTIONS : "احساسات",
            EMOTIONS_HEADER_DESC : "شما در این قسمت میتوانید احساسات نیروهای خود را ببینید",
            EMOTIONS_REPORT_BTN : "گزارش",
            EMOTIONS_REPORT_ALL_GROUPS : "همه گروهها",
            EMOTIONS_REPORT_TO : "تا",
            EMOTIONS_TABLE_COL_FULL_NAME : "نام و نام خانوادگی",
            EMOTIONS_TABLE_COL_GOLD_HAPPY : "شاد",
            EMOTIONS_TABLE_COL_RED_SAD : "ناراحت",
            EMOTIONS_TABLE_COL_NORMAL : "معمولی",
            EMOTIONS_TABLE_COL_ANGRY : "عصبانی",
            EMOTIONS_TABLE_COL_DETAIL : "جزییات",



            MEDIA_TYPE_TITLE_CHAL :"چالش",
            MEDIA_TYPE_TITLE_GIFT :"هدیه",
            MEDIA_TYPE_TITLE_NOTIFICATION :"اطلاعیه",
            MEDIA_TYPE_TITLE_POLL :"نظرسنجی",
            MEDIA_TYPE_TITLE_WELCOME :"خوش آمد گویی",

            MEDIA_TITLE_MEDIA : "مدیاها",
            MEDIA_HEADER_DESC : "شما در این قسمت میتوانید مدیاهای خود را ببینید",
            MEDIA_TABLE_COL_TYPE : "نوع",
            MEDIA_TABLE_COL_SENDER : "ارسال کننده",
            MEDIA_TABLE_COL_DATE : "تاریخ",
            MEDIA_TABLE_COL_DELETE : "حذف",
            MEDIA_TABLE_DELETE_ALERT : "آیا از پاک شدن این مدیا مطمئن هستید ؟ ",
            MEDIA_TABLE_DELETE_ACCEPT : "پاک شود",
            MEDIA_TABLE_DELETE_CANCEL : "منصرف شدم",


            ACCOUNT_MESSAGE_USER_IN_USE : "تعداد کاربران استفاده شده شما بیشتر از تعداد کاربران کاهش داده می باشد",
            ACCOUNT_MESSAGE_USER_DEC_ERROR : "شما قبل از اتمام تاریخ اکانت خود نمی توانید کاربران خود را کاهش دهید",

            ACCOUNT_MESSAGE_GROUP_IN_USE : "تعداد گروههای استفاده شده شما بیشتر از تعداد گروههای کاهش داده می باشد",
            ACCOUNT_MESSAGE_GROUP_DEC_ERROR : "شما قبل از اتمام تاریخ اکانت خود نمی توانید گروههای خود را کاهش دهید",

            ACCOUNT_MESSAGE_PACKAGE_IN_USE : "تعداد پکیج های استفاده شده شما بیشتر از تعداد پکیج های کاهش داده می باشد",
            ACCOUNT_MESSAGE_PACKAGE_DEC_ERROR : "شما قبل از اتمام تاریخ اکانت خود نمی توانید پکیج های خود را کاهش دهید",

            ACCOUNT_MESSAGE_TOTAL_PRICE_IS_ZERO : "مبلغ قابل پرداخت صفر میباشد",

            ACCOUNT_TITLE_MEDIA : "حساب",
            ACCOUNT_HEADER_DESC : "شما میتوانید در این قسمت اعتبار حیاب خود را تمدید و مدیریت کنید",
            ACCOUNT_HEADER_ADD_BTN: "تمدید اعتبار",
            ACCOUNT_HEADER_UPGRADE_BTN: "ارتقاء پلن ",
            ACCOUNT_BODY_TITLE: "اعتبار",
            ACCOUNT_LEFT_ONE: "مشترک عزیز شما تا",
            ACCOUNT_LEFT_TWO: "روز دیگر نیاز به تمدید اعتبار خود دارید",
            ACCOUNT_MILKY_DESC: "پلن آندرومدا برای شرکت ها و سازمانهایی با سایز متوسط با اندازه کارمندان از 50 تا 100 مناسب میباشد . و دارای امکاناتی از قبیل ساخت نظرسنجی , ارسال اطلاعیه , ارسال گیفت کارت , انتقال ستاره , دادن اخطار , حال کارمندان سازمان , گزارش های پیشرفته ... میباشد , با ما سازمان خود را زنده نگه دارید",

            ACCOUNT_MODAL_TITLE: "اعتبار",
            ACCOUNT_MODAL_PERSON_UNIT: "نفر",
            ACCOUNT_MODAL_DAY: "روز",
            ACCOUNT_MODAL_MONTH: "ماه",
            ACCOUNT_MODAL_YEAR: "سال",
            ACCOUNT_MODAL_PRICE_UNIT: "تومان",
            ACCOUNT_MODAL_PAYABLE_AMOUNT: "مبلغ قابل پرداخت",
            ACCOUNT_MODAL_PAY_BTN: "پرداخت",
            ACCOUNT_MODAL_ROW_LEFT_DAYS: "روزهای مانده",
            ACCOUNT_MODAL_ROW_LEFT_DAYS_COST: "هزینه روزهای مانده",
            ACCOUNT_MODAL_ROW_PLAN_COST: "هزینه پلن انتخابی",
            ACCOUNT_MODAL_ROW_PLAN_DURATION: "مدت زمان پلن انتخابی",


            SETTING_MESSAGE_EDIT_ERROR : "مشکل در ویرایش اطلاعات",
            SETTING_MESSAGE_ORGAN_SUCCESSFULLY_EDITED : "اطلاعات سازمان شما با موفقیت ویرایش شد",
            SETTING_MESSAGE_WRONG_CODE : "کد تایید اشتباه می باشد",
            SETTING_MESSAGE_ENTER_CODE : "لطفا کد تایید را وارد نمایید",
            SETTING_MESSAGE_ENTER_MOBILE : "لطفا شماره موبایل را وارد نمایید",
            SETTING_MESSAGE_PROBLEM_SEND_CODE : "مشکل در ارسال کد تایید",
            SETTING_MESSAGE_SEND_CODE : "کد تایید برای شما ارسال شد",
            SETTING_MESSAGE_MOBILE_CHANGE_SUCCESSFULLY : "شماره موبایل شما با موفقیت تغییر یافت",
            SETTING_CHANGE_MOBILE_NUMBER : "تغییر شماره موبایل",
            SETTING_ENTER_NEW_MOBILE_NUMBER : "شماره موبایل جدید را وارد نمایید",
            SETTING_ENTER_CODE : "کد تایید ارسال شده را وارد نمایید",
            SETTING_APPROVE_CODE : "تایید کد",
            SETTING_SEND_VERIFY_CODE : "ارسال کد تایید",

            SETTING_TITLE_MEDIA : "تنظیمات",
            SETTING_HEADER_DESC : "شما در این قسمت میتوانید تنظیمات مربوط به سازمان خود را ست کنید",
            SETTING_BODY_TITLE : "اطلاعات پایه",
            SETTING_ORGAN_NAME : "نام سازمان",
            SETTING_BODY_LOGIN_INFO : "اطلاعات ورود",
            SETTING_USERNAME : "نام کاربری",
            SETTING_PASSWORD : "پسوورد",
            SETTING_MOBILE : "شماره موبایل",
            SETTING_MOBILE_PLACE_HOLDER : "شماره موبایل برای احراز هویت",
            SETTING_MOBILE_CHANGE : "تغییر شماره موبایل",
            SETTING_SAVE_BTN : "ذخیره تغییرات",

            LOGIN_HEADER_MENU_SUPPORT:"پشتیبانی",
            LOGIN_HEADER_MENU_LEARN:"آموزش",
            LOGIN_HEADER_MENU_WEBSITE:"وب سایت",

            LOGIN_FORM_TITLE_LOGIN_WITH_USERNAME:"ورود با نام کاربری",
            LOGIN_FORM_TITLE_LOGIN_WITH_MOBILE:"ورود با موبایل",
            LOGIN_FORM_FILED_MOBILE:"شماره موبایل",
            LOGIN_FORM_FILED_USERNAME:"نام کاربری",
            LOGIN_FORM_FILED_ORGAN_NAME:"نام ارگان",
            LOGIN_FORM_FILED_PASSWORD:"پسوورد",
            LOGIN_FORM_SUBMIT_BTN:"ورود",
            LOGIN_FORM_SEND_CODE_BTN:"ارسال کد",
            LOGIN_FORM_TITLE_CODE:"کد تایید",


            LOGIN_MESSAGE_USERNAME : "لطفا نام کاربری را وارد نمایید",
            LOGIN_MESSAGE_PASSWORD : "لطفا پسوورد را وارد نمایید",
            LOGIN_MESSAGE_MOBILE : "لطفا شماره موبایل را وارد نمایید",
            LOGIN_MESSAGE_WRONG_USER_PASSWORD : "نام کاربری و یا رمز عبور اشتباه میباشد",
            LOGIN_MESSAGE_WRONG_MOBILE : "شماره موبایل صحیح نمی باشد",
            LOGIN_MESSAGE_WRONG_MOBILE_CODE : "شماره موبایل و یا کد ارسالی صحیح نمی باشد",
            LOGIN_MESSAGE_SEND_CODE : "کد تایید برای شما ارسال شد",
            LOGIN_MESSAGE_ENTER_CODE : "لطفا کد ارسال شده را وارد نمایید",
            LOGIN_MESSAGE_ENTER_MOBILE : "لطفا شماره موبایل را وارد نمایید",
            LOGIN_MESSAGE_ENTER_ORGAN_NAME : "لطفا نام سازمان خود را وارد نمایید",
            LOGIN_MESSAGE_WRONG_ORGAN_NAME : "نام سازمان صحیح نمی باشد",




        },
    },
};
i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        lng: "fa",
        interpolation: {
            escapeValue: false,
        },
    });
export default i18next;
