import React, {useState} from "react"
import "./Login.scss";
import {useHistory} from "react-router-dom";
// import headerLogo from "../../assets/images/header_logo.svg";
// import mainLogo from "../../assets/images/main_logo.svg";
// import billboard from "../../assets/images/billboard_back.jpg";
// import mobileIcon from "../../assets/images/mobile_icon.png";
import {Spin, message, Switch} from 'antd';
import "antd/dist/antd.css"
import {login_api, login_username_api, setOrganName_api, verify_api} from "../../Services/Services";

import { useTranslation } from "react-i18next";
import {Setup} from "../../Services/Setup";

const headerLogo = Setup.ImagePanelPoint+"images/header_logo.svg";
const mainLogo = Setup.ImagePanelPoint+"images/main_logo.svg";
const billboard = Setup.ImagePanelPoint+"images/billboard_back.jpg";
const mobileIcon = Setup.ImagePanelPoint+"images/mobile_icon.png";

function Login() {

    const history = useHistory();
    const { t,i18n  } = useTranslation();

    const [loading,set_loading] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState("");
    const [username, set_username] = useState("");
    const [password, set_password] = useState("");
    const [code, set_code] = useState("");
    const [mode, set_mode] = useState("login"); //login | verify
    const [name, set_name] = useState("");
    const [organ, set_organ] = useState({});
    const [loginModeName, set_loginModeName] = useState("ورود با نام کاربری");
    const [loginMode, set_loginMode] = useState("username");



    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
        set_loginModeName(checked?"ورود با نام کاربری":"ورود با موبایل")
        set_loginMode(checked?"username":"mobile")
    };




    // username login

    const usernameLogin = async ()=>{
        if(username.length<1){
            message.warning({
                content: t("LOGIN_MESSAGE_USERNAME"),
                className: 'toast-class',
            });
            return ;
        }

        if(password.length<1){
            message.warning({
                content: t("LOGIN_MESSAGE_PASSWORD"),
                className: 'toast-class',
            });
            return ;
        }

        set_loading(true)
        let obj = {
            username:username,
            password:password
        }
        try{
            const res = await login_username_api(obj);
            set_loading(false);
            let {organ,accessToken} = res.data.data;
            localStorage.setItem("token",accessToken);
            set_organ(organ)
            if(organ.name==="" || !organ.name){
                set_mode("name")
                return
            }
            history.push("/panel/groups")
        }catch (e) {
            set_loading(false)
            console.log(e)
            message.warning({
                content: t("LOGIN_MESSAGE_WRONG_USER_PASSWORD"),
                className: 'toast-class',
            });
        }

    }



    const login = async ()=>{
        if(phoneNumber.length<1){
            console.log("mobile empty");
            message.warning({
                content: t("LOGIN_MESSAGE_ENTER_MOBILE"),
                className: 'toast-class',
            });
            return ;
        }

        set_loading(true)
        try{
            await login_api(phoneNumber);
            set_loading(false);
            set_mode("verify");
            message.success({
                content: t("LOGIN_MESSAGE_SEND_CODE"),
                className: 'toast-class'
            });
        }catch (e) {
            set_loading(false)
            console.log(e)
            message.warning({
                content: t("LOGIN_MESSAGE_WRONG_MOBILE"),
                className: 'toast-class',
                duration: 10,
            });
        }

    }


    const verify = async ()=>{
        //validate
        if(code.length<1){
            console.log("code empty");
            message.warning({
                content: t("LOGIN_MESSAGE_ENTER_CODE"),
                className: 'toast-class'
            });
            return;
        }

        if(phoneNumber.length<1){
            console.log("mobile empty");
            message.warning({
                content: t("LOGIN_MESSAGE_ENTER_MOBILE"),
                className: 'toast-class'
            });
            return ;
        }

        set_loading(true);
        try{
            const res = await verify_api({mobile:phoneNumber,code});
            set_loading(false);
            let {organ,accessToken} = res.data.data;
            localStorage.setItem("token",accessToken);
            set_organ(organ)
            if(organ.name==="" || !organ.name){
                set_mode("name")
                return
            }

            history.push("/panel/groups")

        }catch (e) {
            set_loading(false)
            console.log(e)
            message.warning({
                content: t("LOGIN_MESSAGE_WRONG_MOBILE_CODE"),
                className: 'toast-class'
            });
        }
    }



    const organSetName = async ()=>{
        //validate
        if(name.length<1){
            message.warning({
                content: t("LOGIN_MESSAGE_ENTER_ORGAN_NAME"),
                className: 'toast-class'
            });
            return;
        }


        set_loading(true);
        try{
            await setOrganName_api({organId:organ._id,name});
            set_loading(false);
            history.push("/panel/groups")

        }catch (e) {
            set_loading(false)
            console.log(e)
            message.warning({
                content: t("LOGIN_MESSAGE_WRONG_ORGAN_NAME"),
                className: 'toast-class'
            });
        }
    }





    return(
        <div className="new-login-container">
            <div className="new-login-header">

                <div className="new-login-header-logo">
                    <img src={headerLogo} alt=""/>
                </div>

                {/*<div className="new-login-menu">*/}
                {/*    <span>{t("LOGIN_HEADER_MENU_SUPPORT")}</span>*/}
                {/*    <span>{t("LOGIN_HEADER_MENU_LEARN")}</span>*/}
                {/*    <span>{t("LOGIN_HEADER_MENU_WEBSITE")}</span>*/}
                {/*</div>*/}

            </div>
            <div className="new-login-body">
                <div className="new-login-frame-offset"></div>
                <div className="new-login-frame">

                    <div className="new-login-body-billboard" style={{
                        backgroundImage:`url(${billboard})`
                    }}>
                        <div className="billboard-wrapper">
                            <span className="billboard-title">STARIUM</span>
                            <span className="billboard-text">FOR ALL STAR</span>
                        </div>

                    </div>



                    <Spin spinning={loading}  wrapperClassName="new-login-body-form">
                        {mode==="login"?                        <div className="new-login-spin-form">

                                {/*logo*/}
                                <div className="main-logo">
                                    <img src={mainLogo} alt=""/>
                                </div>

                                {/*mode*/}
                                <div className="mode login-name">
                                    <span>{loginModeName}</span>
                                </div>


                                    <Switch defaultChecked onChange={onChange} />
                                {/*input*/}
                                <div className="new-login-input">
                                    {loginMode === "username"?
                                        <div className="username-mode">

                                            <div className="login-input-row">
                                                <div className="input-sec">
                                                    {/*<img src={mobileIcon} alt=""/>*/}
                                                    <input type="text" name="" id="" placeholder=""
                                                           value={username}
                                                           onKeyPress={e=>{
                                                               if(e.key==="Enter"){
                                                                   usernameLogin()
                                                               }
                                                           }}
                                                           onChange={e=>set_username(e.target.value)}
                                                    />
                                                </div>

                                                <div className="message-sec">
                                                    <span>{t("LOGIN_FORM_FILED_USERNAME")}</span>
                                                </div>
                                            </div>

                                            <div className="login-input-row">
                                                <div className="input-sec">
                                                    {/*<img src={mobileIcon} alt=""/>*/}
                                                    <input type="text" name="" id="" placeholder=""
                                                           value={password}
                                                           onKeyPress={e=>{
                                                               if(e.key==="Enter"){
                                                                   usernameLogin()
                                                               }
                                                           }}
                                                           onChange={e=>set_password(e.target.value)}
                                                    />
                                                </div>

                                                <div className="message-sec">
                                                    <span>{t("LOGIN_FORM_FILED_PASSWORD")}</span>
                                                </div>
                                            </div>
                                        </div>:
                                        <div className="mobile-mode">
                                            <div className="login-input-row">
                                                <div className="input-sec">
                                                    {/*<img src={mobileIcon} alt=""/>*/}
                                                    <input type="text" name="" id="" placeholder=""
                                                           value={phoneNumber}
                                                           onKeyPress={e=>{
                                                               if(e.key==="Enter"){
                                                                   login()
                                                               }
                                                           }}
                                                           onChange={e=>setPhoneNumber(e.target.value)}
                                                    />
                                                </div>

                                                <div className="message-sec">
                                                    <span>{t("LOGIN_FORM_FILED_MOBILE")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }




                                </div>

                                {loginMode==="username"?<div className="submit-btn" onClick={usernameLogin}>
                                    <span>{t("LOGIN_FORM_SUBMIT_BTN")}</span>
                                </div>:<div className="submit-btn" onClick={login}>
                                    <span>{t("LOGIN_FORM_SEND_CODE_BTN")}</span>
                                </div>}

                            </div>
                            :null}

                        {mode==="verify"?<div className="new-login-spin-form">

                                {/*logo*/}
                                <div className="main-logo">
                                    <img src={mainLogo} alt=""/>
                                </div>

                                {/*mode*/}
                                <div className="mode">
                                    <span>{t("LOGIN_FORM_TITLE_CODE")}</span>
                                </div>

                                {/*input*/}
                                <div className="new-login-input">

                                    <div className="input-sec">
                                        {/*<img src={mobileIcon} alt=""/>*/}
                                        <input className="token-input" type="text" name="" id="" placeholder="XXXXXX"
                                               value={code}
                                               onKeyPress={e=>{
                                                   if(e.key==="Enter"){
                                                       verify()
                                                   }
                                               }}
                                               onChange={e=>set_code(e.target.value)}
                                        />
                                    </div>

                                    <div className="message-sec">
                                        <span>{t("LOGIN_FORM_TITLE_CODE")}</span>
                                    </div>

                                </div>

                                <div className="submit-btn" onClick={verify}>
                                    <span>{t("LOGIN_FORM_SUBMIT_BTN")}</span>
                                </div>

                            </div>
                            :null}

                        {mode==="name"?<div className="new-login-spin-form">

                                {/*logo*/}
                                <div className="main-logo">
                                    <img src={mainLogo} alt=""/>
                                </div>

                                {/*mode*/}
                                <div className="mode">
                                    <span>{t("LOGIN_FORM_FILED_ORGAN_NAME")}</span>
                                </div>

                                {/*input*/}
                                <div className="new-login-input">

                                    <div className="input-sec">
                                        <img src={mobileIcon} alt=""/>
                                        <input type="text" name="" id="" placeholder="company"
                                               value={name}
                                               onChange={e=>set_name(e.target.value)}
                                        />
                                    </div>

                                    <div className="message-sec">
                                        <span>{t("LOGIN_FORM_FILED_ORGAN_NAME")}</span>
                                    </div>

                                </div>

                                <div className="submit-btn" onClick={organSetName}>
                                    <span>{t("LOGIN_FORM_SUBMIT_BTN")}</span>
                                </div>

                            </div>
                            :null}

                    </Spin>


                </div>
                <div className="new-login-frame-offset"></div>
            </div>
        </div>
    )
}

export default Login